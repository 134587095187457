import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import imagesLoaded from "imagesloaded"
import uuid from "uuid"
import Project from "./Project"
import StyledProjects from "../styles/StyledProjects"
import { _s } from "../utils/globals/helperFunctions"
import "../utils/effects/Math"
import StretchEffect from "../utils/effects/StretchEffect"

const Projects = ({ cms, goToProjectPage, data }) => {
  const [list, setList] = useState([])

  const animateEyeColor = mouseOver => {
    const eyes = _s(".eye")

    eyes.map(eye => eye.classList[mouseOver ? "add" : "remove"]("animate-eye"))
  }

  const generateProjects = async () => {
    setList(
      cms.map((title, i) => (
        <Project
          title={title.projectTitle}
          path={title.path}
          animateEyeColor={animateEyeColor}
          key={uuid()}
          number={i}
          goToProjectPage={goToProjectPage}
          data={data}
        />
      ))
    )
  }
  const preloadImages = () => {
    return new Promise((resolve, reject) => {
      imagesLoaded(document.querySelectorAll("img"), resolve)
    })
  }

  useEffect(() => {
    generateProjects().then(() =>
      preloadImages().then(() => {
        if (typeof window === `undefined`) return
        if (window.innerWidth > 770) {
          const container = document.body
          const wrapper = document.querySelector(".project-container")
          const stretch = new StretchEffect(container, wrapper)
        }
      })
    )
  }, [])
  return <StyledProjects className="project-container">{list}</StyledProjects>
}

Projects.propTypes = {
  titles: PropTypes.array,
}

export default Projects
