import React from "react"
import PropTypes from "prop-types"
import StyledPowers from "../styles/StyledPowers"
import LightningSvg from "../images/svgs/LightningSvg"
const Powers = ({ title }) => {
  return (
    <StyledPowers>
      <h1>{title}</h1>
      <LightningSvg />
      <div className="power__grid">
        <div className="web-design--column">
          <h2>Web Design</h2>
          <p>Styled Components</p>
          <p>SCSS/SASS</p>
          <p>Figma</p>
          <p>Sketch</p>
          <p>Adobe Photoshop</p>
          <p>Adobe Illustrator</p>
        </div>
        <div className="javascript--column">
          <h2>JavaScript</h2>
          <p>React</p>
          <p>Vanilla JavaScript</p>
          <p>Next.js</p>
          <p>GatsbyJS</p>
        </div>
        <div className="database-versioning--column">
          <h2>Data</h2>
          <p>node.js</p>
          <p>Apollo</p>
          <p>GraphQL</p>
          <p>Postman</p>       
        </div>
        <div className="package-cms--column">
          <h2>Extras</h2>
          <p>GreenSock</p>
          <p>React spring</p>
          <p>animejs</p>        
          <p>ThreeJS</p>
          <p>CypressJS</p>
        </div>
      </div>
    </StyledPowers>
  )
}

Powers.propTypes = {}

export default Powers
