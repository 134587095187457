function Eye(sel) {
  this.eye = document.querySelector(sel)
  this.container = document.querySelector(".my-picture__container")
  this.pupil = this.eye.children[0]
  this.lid = this.eye.children[1]

  // widths

  // centered position

  // state
  this.bLidUp = true
}

Eye.prototype.movePupil = function(r, theta) {
  this.ew = this.eye.offsetWidth
  this.pw = this.pupil.offsetWidth

  let x, y

  if (r > 1) r = 1 // clamp
  r *= this.ew / 2 - this.pw / 2 // restrict edge of pupil to edge of eye

  // convert polar to rectangular
  x = r * Math.cos(theta) + (this.ew - this.pw) / 2
  y = r * Math.sin(theta) + (this.ew - this.pw) / 2

  this.pupil.style.transform =
    "translateX(" + x + "px)" + "translateY(" + (y - 10) + "px)"
}

Eye.prototype.blink = function() {
  if (this.bLidUp) {
    this.bLidUp = false
    this.lid.style.transform = "translateY(80%)"
  } else {
    this.bLidUp = true
    this.lid.style.transform = "translateY(0%)"
  }
}

export default Eye
