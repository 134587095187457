import React from "react"
import StyledAwwwards from "../styles/StyledAwwwards"
import { Link } from "gatsby"

const Awwwards = ({ cms, goToProjectPage }) => {
  return (
    <StyledAwwwards onClick={() => goToProjectPage(3, "doctorwho")}>
      <div className="awwwards--innerbox" />
      <div className="awwwards--innerbox" />
      <h2>{cms}</h2>
    </StyledAwwwards>
  )
}

export default Awwwards
