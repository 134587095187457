import styled, { keyframes } from "styled-components"

const multicolorHover = keyframes`
14.3% {
fill: #ff0000;
} 
     28.6% {
fill: #f7ac1a;
     } 
    42.9% {
fill:  #ecef34;
    }
    57.4% {
fill:  #05ff00;
    }  
    71.7% {
fill:  #00f0ff;
    }  
    86% {
fill:  #2400ff;
    }  
    100% {
fill:  #fa00ff;
    }  
`

const StyledMyFun = styled.div`
  width: 90%;
  .sound__icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -0.4rem);
    width: 2.1rem;
    height: 2.1rem;
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      transform: translate(-50%, -0.3rem);
      width: 1.3rem;
      height: 1.3rem;
    }
    @media only screen and (max-width: 640px) {
      transform: translate(-50%, -0.2rem);
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  .music--svg {
    width: 2.22vw;
    height: 1.92vw;
  }
  @media only screen and (max-width: 640px) {
    .music--svg {
      height: 1.2rem;
      width: 1rem;
    }
  }

  .noise-link {
    text-decoration: underline;
  }
  & h1,
  h2 {
    font-family: "Pano";
    font-weight: bold;
  }
  & h2 {
    font-size: 1.14vw;
  }
  @media only screen and (max-width: 640px) {
    & h2 {
      font-size: 16px;
    }
    & h1 {
      font-size: 1.7rem;
      line-height: 2.7rem;
      text-shadow: 2px 2px #00ff9f6e;
    }
  }
  & h1 {
    font-size: 5.55vw;
    line-height: 6.66vw;
  }
  @media only screen and (max-width: 640px) {
    & h1 {
      font-size: 1.7rem;
      line-height: 2.7rem;
    }
  }
  & .noise-shapes {
    width: 35rem;
    height: 26rem;
    position: relative;
    display: grid;
    grid-template-columns: 10.375rem 22rem;
    grid-template-rows: 12rem 10rem;
    grid-column-gap: 0.7rem;
    grid-row-gap: 0.7rem;
    margin-bottom: 4rem;
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      width: 20rem;
      height: 15rem;
      position: relative;
      display: grid;
      grid-template-columns: 7rem 13rem;
      grid-template-rows: 9rem 6rem;
      grid-column-gap: 0.3rem;
      grid-row-gap: 0.3rem;
      margin-bottom: 6rem;
    }
    @media only screen and (max-width: 640px) {
      width: 100%;
      height: 26rem;
      position: relative;
      display: grid;
      grid-template-columns: 40% 60%;
      grid-template-rows: 6rem 4.5rem;
      grid-column-gap: 0.7rem;
      grid-row-gap: 0.7rem;
      margin-bottom: 0rem;
    }
    & .top-left {
      grid-column: 1/2;
      grid-row: 1/2;
      border: 1px solid black;
      overflow: hidden;
      position: relative;
      & .zig-zag {
        width: 100%;
        height: 108%;
        top: -0.25rem;
        position: absolute;
        &.animate {
          & > g {
            animation: ${multicolorHover} 0.5s steps(7) infinite;
          }
        }
      }
    }
    & .top-right {
      grid-column: 2/3;
      grid-row: 1/2;
      border: 1px solid black;
    }
    & .bottom {
      grid-row: 2/3;
      grid-column: 1/3;
      border: 1px solid black;
      position: relative;
      padding: 0.7rem 2rem;
      @media only screen and (max-width: 640px) {
        padding: 0.5rem 0.5rem;
      }
    }
  }
  & .circle-group {
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    &.animate {
      & circle {
        animation: ${multicolorHover} 0.5s steps(7) infinite;
      }
    }
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    background: #222;
  }

  ul {
    height: 90%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    position: relative;
    border: 1px solid #160801;
    border-radius: 1em;
    background: white;
  }

  li {
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    float: left;
  }

  & .white {
    height: 95%;
    width: calc(100% / 9);
    z-index: 1;
    border-right: 1px solid black;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    border-radius: 2px;
    background: linear-gradient(to bottom, #eee 0%, #fff 100%);
    cursor: pointer;
  }

  & .white:active {
    border-top: 1px solid #777;
    border-left: 1px solid #999;
    border-bottom: 1px solid #999;
    box-shadow: 2px 0 3px rgba(0, 0, 0, 0.1) inset,
      -5px 5px 20px rgba(0, 0, 0, 0.2) inset, 0 0 3px rgba(0, 0, 0, 0.2);
    background: linear-gradient(to bottom, #fff 0%, #e9e9e9 100%);
  }

  .black {
    height: 50%;
    width: 2em;
    margin: 0 0 0 -1em;
    z-index: 2;
    border: 1px solid #000;
    border-radius: 0 0 3px 3px;
    background: black;
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      width: 1.5em;
      margin: 0 0 0 -0.5em;
    }
    @media only screen and (max-width: 640px) {
      width: 1em;
      margin: 0 0 0 0em;
      transform: translate(-0.5em, 0);
    }
  }

  .a,
  .g,
  .f,
  .d,
  .c {
    margin: 0 0 0 -1em;
  }

  & li:first-child {
    border-left: 1px solid black;
  }
`

export default StyledMyFun
