import styled, { keyframes } from "styled-components"

const moveRight = width => keyframes`
0% {
  transform: translate(0)
}
100% {
  transform: translate(${width})
}
`

const StyledRightLanding = styled.section`
  width: 60vw;
  position: relative;
  & .sprite-container {
    overflow: hidden;
    width: 16.66vw;
    margin-top: 5vw;
    & .sprite-image {
      width: 100vw;
      animation: ${() => moveRight("-100vw")} 0.6s steps(6) infinite;
    }
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
    position: relative;
    & .sprite-container {
      overflow: hidden;
      width: 220px;
      & .sprite-image {
        width: 1320px;
        animation: ${() => moveRight("-1320px")} 0.6s steps(6) infinite;
      }
    }
  }
`

export default StyledRightLanding
