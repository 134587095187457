import React, { useState, useContext, useEffect, useRef } from "react"
import { Link, graphql, navigate } from "gatsby"
import gsap, { TimelineMax } from "gsap"
import Img from "gatsby-image"
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import Header from "../components/Header"
import SEO from "../components/seo"
import ProgressBar from "../components/ProgressBar"
import PageSwiper from "../components/PageSwiper"
import RightLanding from "../components/RightLanding"
import Loader from "../components/Loader"

import MyPicture from "../components/MyPicture"
import Footer from "../components/Footer"

import HonorableMention from '../images/svgs/HonorableMention'

import StyledMainContent from "../styles/StyledMainContent"
import StyledLandingBackground from "../styles/StyledLandingBackground"

import {
  _,
  _s,
  MathUtils,
  mediaCheck,
  calcWinSize,
} from "../utils/globals/helperFunctions"

const IndexPage = ({ data }) => {
  const [progress, setProgress] = useState(0)
  const [keyEvent, setKeyEvent] = useState(0)
  const [swiped, setSwiped] = useState(false)
  const [swiperLeft, setSwiperLeft] = useState("-100%")
  const [bgColorInt, setBgColorInt] = useState(0)
  const [hideLoader, setHideLoader] = useState(false)
  const [showRossGif, setShowRossGif] = useState(false)
  const [deviceDesktop, setDeviceDesktop] = useState(false)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const [init, setInit] = useState(false)

  const mobileMedia =
    typeof window !== `undefined`
      ? window.matchMedia("(max-width: 640px)")
      : null
  let currentPixel = typeof window !== `undefined` ? window.pageYOffset : null

  const anim = useRef(null)
  const containerRef = useRef(null)

  const theme = {}

  let _event = {
    y: 0,
    deltaY: 0,
  }
  let percentage = 0
  let maxHeight

  const onWheel = e => {
    e.stopImmediatePropagation()
    e.stopPropagation()
    e.preventDefault()
    var evt = _event
    evt.deltaY = e.wheelDeltaY || e.deltaY * -1
    evt.deltaY *= 0.5

    scroll(e)
  }

  const setSize = () => {
    maxHeight = _("[data-scroll-content").offsetHeight - window.innerHeight
  }

  const scroll = e => {
    setSize()
    var evt = _event
    if (evt.y + evt.deltaY > 0) {
      evt.y = 0
    } else if (-(evt.y + evt.deltaY) >= maxHeight) {
      evt.y = -maxHeight
    } else {
      evt.y += evt.deltaY
    }
  }

  const toggleRoss = bool => {
    setShowRossGif(bool)
    const eyes = _s(".eye")
    eyes.map(eye => (eye.style.opacity = bool ? 0 : 1))
  }

  const looper = () => {
    const section = _("[data-scroll-content]")

    const newPixel = window.pageYOffset
    const diff = newPixel - currentPixel
    const sp = diff * 0.2
    section.style.transform = `skewY(${sp}deg)`
    const progressBar = _(".progress-bar")
    progressBar.style.transform = `skewY(${sp * 4}deg)`
    currentPixel = newPixel
  }

  const handleCustomScroll = () => {
    maxHeight = _("[data-scroll-content").offsetHeight - window.innerHeight
    const progressBar = _(".progress-bar")
    percentage = MathUtils.lerp(percentage, _event.y, 0.05)

    const newCircleLength =
      Math.round(percentage * (percentage < 0 ? -100 : 100)) / 100

    window.scrollTo(0, newCircleLength)
    progressBar.setAttribute(
      "style",
      `-webkit-mask-image: linear-gradient(to bottom, black ${(newCircleLength /
        maxHeight) *
        100}%, transparent 0%)`
    )
  }

  const animate = () => {
    anim.current = window.requestAnimationFrame(animate)
    handleCustomScroll()
    looper()
  }

  const cancelAnimation = async () => {
    if (anim.current) {
      window.cancelAnimationFrame(anim.current)
      anim.current = undefined
    }
  }

  const stopAnimate = async () => {
    cancelAnimation()
  }

  const stopEventListeners = async () => {
    if (containerRef.current) {
      containerRef.current.removeEventListener("wheel", onWheel, false)
      containerRef.current.removeEventListener("scroll", onWheel, false)
      containerRef.current = undefined
    }
  }

  const scrollWithKeys = e => {
    debugger
    var evt = _event
    if (evt.y + evt.deltaY > 0) {
      evt.y = 0
    } else if (-(evt.y + evt.deltaY) >= maxHeight) {
      evt.y = -maxHeight
    } else if (e.keyCode == 40) {
      evt.y -= 10
    } else if (e.keyCode == 38) {
      evt.y += 10
    }
  }

  const swipeToProject = (n, path) => {
    const swipe = _(".page-swiper")
    const titles = _s(".project-title")

    if (typeof window === `undefined`) return
    titles.map((title, i) => {
      gsap.to(title, 0.3, {
        opacity: 0,
        delay: 0.1 * i,
        ease: gsap.Power4,
        onComplete: () => {
          if (i === titles.length - 1) {
            setSwiperLeft(0)
            setBgColorInt(n)
            setSwiped(true)
            swipe.addEventListener("transitionend", () => {
              navigate(`/${path}`, {
                state: { prevPath: window.location.pathname },
              })
            })
          }
        },
      })
    })
    //
  }

  const animateCover = () => {
    const animator = document.querySelector(".loader--info")

    const tl = new TimelineMax({})
    tl.to(".loader__cover", 0.8, {
      scaleX: 1,
      delay: 5,
      ease: gsap.Power4,
      transformOrigin: "left",
      onComplete: () => setHideLoader(true),
    })
    tl.to(".loader__cover", 0.8, {
      scaleX: 0,
      ease: gsap.Power4,
      transformOrigin: "right",
      onComplete: () => {
        setHideLoader(true)
      },
    })
  }

  const addEventListeners = d => {
    animate()
    const container = _(".layout__container")
    containerRef.current = container
    container.addEventListener("wheel", onWheel, {
      passive: false,
    })
    container.addEventListener("scroll", onWheel, {
      passive: false,
    })
    window.addEventListener("resize", setSize)
    window.addEventListener("keydown", scrollWithKeys)
  }

  const goToProjectPage = (number, path) => {
    stopEventListeners().then(() => stopAnimate())
    swipeToProject(number, path)
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      animateCover()
      calcWinSize(setDeviceDesktop)
      mediaCheck(setDeviceDesktop)

      return () => {
        stopAnimate()
        stopEventListeners()
      }
    }
  }, [])

  useEffect(() => {
    if (hideLoader && typeof window !== `undefined`) {
      if (window.innerWidth > 640) {
        animate()
        addEventListeners()
      }
    }
  }, [hideLoader])

  return (
    <React.Fragment>
    <div className="honorable-awwwards" style={{ position: "fixed", zIndex: 999, transform: "translateY(-50%)", top: "50%",  right: 0 }}><a href="https://www.awwwards.com" target="_blank"><HonorableMention /></a></div>
      <ThemeProvider theme={theme}>
        <Header siteTitle="" />
        <StyledLandingBackground>
          <Img fluid={data.dotsBg.childImageSharp.fluid} />
        </StyledLandingBackground>
        <div className="loader__cover" />
        {!hideLoader && <Loader />}
        <PageSwiper
          swiperLeft={swiperLeft}
          swiped={swiped}
          bgColorInt={bgColorInt}
        />

        {deviceDesktop && (
          <MyPicture
            showRossGif={showRossGif}
            deviceDesktop={deviceDesktop}
            hideLoader={hideLoader}
          />
        )}
        <Footer />
        <ProgressBar progress={progress} />
        <Layout data-scroll={mobileMedia ? false : true}>
          <SEO />
          <StyledMainContent data-scroll-content>
            <RightLanding
              goToProjectPage={goToProjectPage}
              toggleRoss={toggleRoss}
              data={data}
            />
            <Footer mobile />
          </StyledMainContent>
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default IndexPage

export const query = graphql`
  query MyQuery {
    dotsBg: file(relativePath: { eq: "dots-bg-1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    animationSprint: file(relativePath: { eq: "animationsprite.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    doctorwho: file(relativePath: { eq: "drwho_landing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    destinationcanada: file(
      relativePath: { eq: "destinationcan_landing.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lfw: file(
      relativePath: { eq: "lfw_landing.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    forevermark: file(relativePath: { eq: "forevermark_landing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    adidas: file(relativePath: { eq: "adidas_landing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    datoCmsAbout {
      aboutInfo
    }
    allDatoCmsProject {
      nodes {
        projectTitle
        path
      }
    }
    datoCmsSkill {
      powers
      awwward
    }
    datoCmsMyFun {
      myFun
      makeNoise
    }
  }
`
