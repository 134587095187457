import styled, { keyframes } from "styled-components"
import React from "react"

const moveHorizontally = x => keyframes`
   0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(${x}, 0);
    }
    100% {
      transform: translate(0, 0);
    }
`

const StyledLoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99998;
  background: white;
  & .loader__cover {
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    &.white {
      background: white;
      transition: width 1s cubic-bezier(0.74, 0.02, 0.23, 1) 0.3s;
      z-index: 4;
    }

    &.black {
      background: black;
      transition: width 1s cubic-bezier(0.74, 0.02, 0.23, 1);
      z-index: 2;
    }
  }
  & > .loader__loadings--container {
    position: absolute;
    width: 100%;
    height: 125vh;
    top: -80vh;
    left: -100vw;
    display: grid;
    grid-template-rows: repeat(8, 20vh);
    grid-gap: 1rem;
    transform: rotate(15deg);

    & .loader__spans--container {
      width: 100%;
      height: 100%;
      font-family: "Pano";
      font-weight: bold;
      font-size: 23vh;
      display: flex;
      align-items: center;
      margin: 0;
      &:nth-of-type(even) {
        margin-left: 18rem;
        animation: ${moveHorizontally("-80vw")} 3s
          cubic-bezier(0.74, 0.02, 0.23, 1) infinite;
      }
      &:nth-of-type(odd) {
        animation: ${moveHorizontally("80vw")} 3s
          cubic-bezier(0.74, 0.02, 0.23, 1) infinite;
      }
      & > .loader__loading {
        text-transform: uppercase;
      }
    }
  }
`

export default StyledLoaderContainer
