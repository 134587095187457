import React from "react"
import styled, { keyframes } from "styled-components"

const moveVertically = y => keyframes`
   0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, -${y * 1}rem);
    }
    100% {
      transform: translate(0, -${y * 2}rem);
    }

`

const StyledInfoContainer = styled.div`
  height: 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  & .loader--info {
    animation: ${moveVertically(2.5)} 5s cubic-bezier(0.66, 0.01, 0, 0.99) 1s;
    animation-fill-mode: forwards;
  }
  & h1 {
    font-family: "Pano";
    font-weight: bold;
    font-style: normal;
    font-size: 1.14vw;
    line-height: 2.5rem;
    margin: 0;
    padding: 0;
    height: 2.5rem;
    text-align: center;
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 640px) {
    & h1 {
      font-size: 15px;
    }
  }
`

export default StyledInfoContainer
