import styled from "styled-components"

const StyledMyPicture = styled.div`
  width: 31vw;
  height: 31vw;
  position: fixed;
  top: 20%;
  right: 65%;
  margin: 0rem 0 0 calc(3rem - 10px);
  background: white;
  z-index: 1000;

  & .photo--me {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1010;
    border: 1px solid black;
  }
  & .ross-gif {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 99999;
  }
  @media only screen and (max-width: 640px) {
    position: relative;
    top: initial;
    right: initial;
    margin: 0 auto;
  }
`

export default StyledMyPicture
