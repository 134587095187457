import React from "react"
import StyledInfoContainer from "../styles/StyledInfoContainer"

const AnimatingLoaderTitle = () => {
  return (
    <StyledInfoContainer>
      <div className="loader--info">
        <h1>Frontend</h1>
        <h1>JavaScript</h1>
        <h1>Developer</h1>
      </div>
    </StyledInfoContainer>
  )
}

export default AnimatingLoaderTitle
