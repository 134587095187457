import React from "react"

function SvgComponent(props) {
  return (
    <svg width={15} height={27} viewBox="0 0 15 27" fill="none" {...props}>
      <path
        d="M14 13.776L8.742 1H1l5.435 8.833H1L14 26 9.226 13.776H14z"
        stroke="#000"
        strokeWidth={2}
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
