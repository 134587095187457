import styled, { keyframes } from "styled-components"

const pr = "1.3vw"
const er = "11.4vw"

const multicolorHover = keyframes`
14.3% {
background-color: #ff0000
} 
     28.6% {
background-color: #f7ac1a
     } 
    42.9% {
background-color: #ecef34
    }
    57.4% {
background-color: #05ff00
    }  
    71.7% {
background-color: #00f0ff
    }  
    86% {
background-color: #2400ff
    }  
    100% {
background-color: #fa00ff
    }  
`

const StyledEyes = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0 auto;
  z-index: 1009;
  & .eye {
    position: absolute;
    width: 2.9vw;
    height: 1.5vw;
    background-color: white;
    border-radius: 50%;
    top: 43.1%;
    transform: translateY(-50%);
    overflow: hidden;
    &#left {
      left: 33.8%;
      box-shadow: inset 0px 0px 0.4vw 0.2vw rgba(0, 0, 0, 0.84);
    }

    &#right {
      right: 34%;
      top: 42.8%;
      box-shadow: inset 0px 0px 0.4vw 0.2vw rgba(0, 0, 0, 0.84);
    }

    &.shadow {
      box-shadow: 0 -0.14vw 0.2vw rgba(0, 0, 0, 0.5);
    }
    &.animate-eye {
      animation: ${multicolorHover} 0.7s steps(7) infinite;
      & > .pupil,
      .lid {
        opacity: 0;
      }
    }
  }

  & .pupil {
    position: absolute;
    width: ${pr};
    height: ${pr};
    transition: all 0.05s linear;
    transform: translateY((${er} - ${pr}) / 2) translateX((${er} - ${pr}) / 2);
    & svg {
      width: 100%;
      height: 100%;
    }
  }

  & .lid {
    position: absolute;
    top: -97%;
    height: 110%;
    width: 100%;
    background-color: #0d0d0d;
    transition: all 0.025s linear;
  }
`

export default StyledEyes
