import React, { useRef, useState, useEffect } from "react"
import StyledEyes from "../styles/StyledEyes.jsx"
import Eye from "./createEye"
import PupilSvg from "../images/svgs/PupilSvg.jsx"

const config = {
  returnSpeed: 0.4,
}

const Eyes = ({ deviceDesktop, hideLoader }) => {
  const [bMouseOver, setBMouseOver] = useState(false)
  const [R, setR] = useState(0)

  const wrapperRef = useRef(null)
  const eyesAnim = useRef(null)

  const eyes = useRef([React.createRef(), React.createRef()])

  const updateEyes = event => {
    // draw()
    let mx = event.pageX,
      my = event.pageY - window.pageYOffset,
      width = window.innerWidth,
      height = window.innerHeight

    let x, y, bboxWidth, bboxHeight, bbRadius

    setBMouseOver(true)

    // center x, y
    x = mx - width / 2
    y = my - height / 2

    // get bbox bounds
    if (wrapperRef.current) {
      bboxWidth = wrapperRef.current.offsetWidth
      bboxHeight = wrapperRef.current.offsetHeight
      bbRadius = Math.sqrt(Math.pow(bboxWidth, 2) + Math.pow(bboxHeight, 2)) / 2

      const newR = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)) / bbRadius
      const newTheta = Math.atan2(y, x)
      for (let i = 0; i < 2; i++) {
        eyes.current[i].current.movePupil(newR, newTheta)
      }
    }
  }

  const returnToNeutral = () => {
    setBMouseOver(false)
    // stopDraw()
  }

  // const draw = async () => {
  //   if (!eyesAnim.current) {
  //     eyesAnim.current = window.requestAnimationFrame(draw)
  //     let dr = config.returnSpeed
  //     if (!bMouseOver && R !== 0) {
  //       dr = Math.abs(R) < 0.01 ? 0.01 : R * dr
  //       setR(R - dr)
  //     }
  //   }
  // }

  // const stopDraw = () => {
  //   if (typeof window !== `undefined`) {
  //     if (eyesAnim.current) {
  //       window.cancelAnimationFrame(eyesAnim.current)
  //       eyesAnim.current = undefined
  //     }
  //   }
  // }

  const blinkEyes = () => {
    eyes.current.forEach(function(eye) {
      eye.current.blink()
    })
    setTimeout(function() {
      eyes.current.forEach(function(eye) {
        eye.current.blink()
      })
    }, 75)
  }

  const blinkLogic = () => {
    const r = Math.random()

    // single blink
    if (r < 0.5) blinkEyes()
    // fast double blink
    else if (r < 0.6) {
      blinkEyes()
      setTimeout(blinkEyes, 120)
    }

    // slow double blink
    else if (r < 0.8) {
      blinkEyes()
      setTimeout(blinkEyes, 500 + Math.random() * 400)
    }
  }

  const initEyes = async () => {
    eyes.current[0].current = new Eye(".eye#left")
    eyes.current[1].current = new Eye(".eye#right")
  }

  const addEvents = () => {
    if (typeof window !== `undefined`) {
      setInterval(blinkLogic, 3500)
      initEyes().then(() => {
        // draw().then(() => {
        document.addEventListener("mousemove", updateEyes, false)
        document.addEventListener("mouseleave", returnToNeutral, false)
        // })
      })
    }
  }

  useEffect(() => {
    if (deviceDesktop && hideLoader) {
      addEvents()
    }

    // window.addEventListener("resize", function() {
    //   wrapperWidth = wrapperRef.current.offsetWidth
    //   wrapperHeight = wrapperRef.current.offsetHeight
    // })
    return () => {
      document.removeEventListener("mousemove", updateEyes, false)
      document.removeEventListener("mouseleave", returnToNeutral, false)
      // stopDraw()
    }
  }, [hideLoader])

  useEffect(() => {
    if (!deviceDesktop) {
      document.removeEventListener("mousemove", updateEyes, false)
      document.removeEventListener("mouseleave", returnToNeutral, false)
      // stopDraw()
    } else {
      addEvents()
    }
  }, [deviceDesktop])
  return (
    <StyledEyes className="wrapper" ref={wrapperRef}>
      <div className="eye" id="left">
        <div className="pupil">
          <PupilSvg />
        </div>

        <div className="lid" />
      </div>
      <div className="eye" id="right">
        <div className="pupil">
          <PupilSvg />
        </div>

        <div className="lid" />
      </div>
    </StyledEyes>
  )
}

export default Eyes
