import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import StyledMyPicture from "../styles/StyledMyPicture"
import BorderBoxes from "../components/BorderBoxes"
import Eyes from "./Eyes"

const MyPicture = ({ showRossGif, deviceDesktop, hideLoader }) => {
  const image = useStaticQuery(graphql`
    query {
      me: file(relativePath: { eq: "me-noeyes.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <StyledMyPicture className="my-picture__container">
      <BorderBoxes />
      {showRossGif ? (
        <img
          className="ross-gif"
          src="https://i.giphy.com/media/WUZVVmCFHHVX6tvr4s/giphy.webp"
          alt="ross from friends gif"
        />
      ) : (
        <Img fluid={image.me.childImageSharp.fluid} className="photo--me" />
      )}

      <Eyes deviceDesktop={deviceDesktop} hideLoader={hideLoader} />
    </StyledMyPicture>
  )
}

export default MyPicture
