import React from "react"

function SvgComponent(props) {
  return (
    <svg width={26} height={30} viewBox="0 0 26 30" fill="none" {...props}>
      <path
        d="M8 3.854L25 1.17v5.976L8 9.83V3.854z"
        fill="#fff"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={4.5}
        cy={25.5}
        r={3.5}
        fill="#fff"
        stroke="#000"
        strokeWidth={2}
      />
      <circle
        cx={21.5}
        cy={22.5}
        r={3.5}
        fill="#fff"
        stroke="#000"
        strokeWidth={2}
      />
      <path stroke="#000" strokeWidth={2} d="M25 1v22M8 6v20" />
    </svg>
  )
}

export default SvgComponent
