import React from "react"

function SvgComponent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 0H4a4 4 0 00-4 4v32a4 4 0 004 4h32a4 4 0 004-4V4a4 4 0 00-4-4zM2 4a2 2 0 012-2h32a2 2 0 012 2v32a2 2 0 01-2 2H4a2 2 0 01-2-2V4zm19 27.959c5.83-.481 10.478-5.13 10.959-10.959H29a1 1 0 110-2h2.959C31.478 13.17 26.829 8.522 21 8.041V11a1 1 0 11-2 0V8.041C13.17 8.522 8.522 13.171 8.041 19H11a1 1 0 110 2H8.041c.481 5.83 5.13 10.478 10.959 10.959V29a1 1 0 112 0v2.959zM6 20c0 7.732 6.268 14 14 14s14-6.268 14-14S27.732 6 20 6 6 12.268 6 20zm9.88-7.291a1 1 0 00-.365 1.366l3.773 6.536a1 1 0 001.195.614l3.864-1.035a1 1 0 10-.517-1.932l-3.11.833-3.473-6.017a1 1 0 00-1.366-.365z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent
