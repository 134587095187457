import React, { useContext } from "react"
import StyledAbout from "../styles/StyledAbout"
import { AppContext } from "../utils/globals/context"

const About = ({ cms }) => {
  return (
    <StyledAbout
      className="about__container"
      textShadow={1}
      data-scroll-content
    >
      <h1>{cms}</h1>
    </StyledAbout>
  )
}

export default About
