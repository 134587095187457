import React from "react"
import Img from "gatsby-image"

import Awwwards from "./Awwwards"
import Projects from "./Projects"
import About from "./About"
import MyFun from "./MyFun"
import Powers from "./Powers"

import StyledRightLanding from "../styles/StyledRightLanding"

const RightLanding = ({ data, toggleRoss, goToProjectPage }) => {
  return (
    <StyledRightLanding>
      <About cms={data.datoCmsAbout.aboutInfo} />
      <div className="sprite-container">
        <div className="sprite-image">
          <Img fluid={data.animationSprint.childImageSharp.fluid} />
        </div>
      </div>
      <Projects
        cms={data.allDatoCmsProject.nodes}
        goToProjectPage={goToProjectPage}
        data={data}
      />
      <Powers title={data.datoCmsSkill.powers} />
      <Awwwards
        cms={data.datoCmsSkill.awwward}
        goToProjectPage={goToProjectPage}
      />
      <MyFun cms={data.datoCmsMyFun} toggleRoss={toggleRoss} />
    </StyledRightLanding>
  )
}

export default RightLanding
