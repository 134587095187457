import styled from "styled-components"
import React from "react"

const StyledLoadingSquare = styled.div`
  width: 25rem;
  height: 25rem;
  position: absolute;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  & .loader__square {
    & path {
      transition: stroke-dashoffset 5s cubic-bezier(0.66, 0.01, 0, 0.99);
    }
  }
  @media only screen and (max-width: 640px) {
    width: 250px;
    height: 250px;
    & svg {
      width: 200px;
      height: 200px;
    }
  }
`

export default StyledLoadingSquare
