import styled from "styled-components"

const StyledPowers = styled.section`
  width: 90%;
  margin-top: 5.7vw;
  & h1,
  h2 {
    font-family: "Pano";
  }
  & h1 {
    font-size: 1.14vw;
  }

  & h2 {
    font-size: 1.14vw;
    color: #8f8e8e;
    margin-bottom: 2.5vw;
  }
  & svg {
    height: 2vw;
    width: 1.1vw;
  }
  @media only screen and (max-width: 640px) {
    margin-top: 8rem;
    & h1 {
      font-size: 16px;
    }

    & h2 {
      font-size: 14px;
      margin-bottom: 2rem;
      height: 30px;
    }
    & svg {
      height: 1.3rem;
      width: 1rem;
    }
  }
  & .power__grid {
    display: grid;
    width: 100%;
    height: 31vw;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    margin-top: 2vw;
    & > .web-design--column {
      grid-column: 1/2;
    }
    & > .javascript--column {
      grid-column: 2/3;
    }
    & > .database-versioning--column {
      grid-column: 3/4;
    }
    & > .package-cms--column {
      grid-column: 4/5;
    }
    & p {
      font-size: 1vw;
      line-height: 2vw;
      font-family: "Pano";
      margin: 2vw 0;
    }
    @media only screen and (max-width: 640px) {
      height: 680px;
      width: 240px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 55% 45%;
      grid-template-areas:
        "one two"
        "three four";
      margin-top: 20px;
      & > .web-design--column {
        grid-area: one;
      }
      & > .javascript--column {
        grid-area: two;
      }
      & > .database-versioning--column {
        grid-area: three;
      }
      & > .package-cms--column {
        grid-area: four;
      }
      & p {
        font-size: 11px;
        line-height: 20px;
        font-family: "Pano";
        margin: 18px 0;
      }
    }
  }
`

export default StyledPowers
