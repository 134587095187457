import React from "react"

function ZigZag(props) {
  return (
    <svg width={184} height={264} viewBox="0 0 184 264" {...props}>
      <g fill="none" fillRule="evenodd" stroke="#000" strokeWidth={2}>
        <path d="M31.833 262.5L1.692 213.031l30.141-49.741L1.692 111.1l30.141-52.91L1.692 1.5M62.14 263L32 213.531l30.14-49.741L32 111.6l30.14-52.91L32 2" />
        <path d="M92.14 263L62 213.531l30.14-49.741L62 111.6l30.14-52.91L62 2" />
        <path d="M122.14 262L92 212.531l30.14-49.741L92 110.6l30.14-52.91L92 1" />
        <path d="M152.14 262L122 212.531l30.14-49.741L122 110.6l30.14-52.91L122 1" />
        <path d="M182.14 262L152 212.531l30.14-49.741L152 110.6l30.14-52.91L152 1" />
      </g>
    </svg>
  )
}

export default ZigZag
