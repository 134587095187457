import React from "react"
import Img from "gatsby-image"
import uuid from "uuid"
import gsap from "gsap"
import PropTypes from "prop-types"
import TransitionLink from "gatsby-plugin-transition-link"

const Project = ({
  title,
  animateEyeColor,
  path,
  goToProjectPage,
  number,
  data,
}) => {
  return (
    <div className="project-title__container" key={uuid()}>
      <h1
        className={`project-title ${path}`}
        onMouseEnter={() => animateEyeColor(true)}
        onMouseLeave={() => animateEyeColor(false)}
        onClick={() => goToProjectPage(number, path)}
      >
        {title}
      </h1>
      <img
        className="project-image"
        src={data[path].childImageSharp.fluid.src}
        alt={`${path} project image`}
      />
    </div>
  )
}

Project.propTypes = {}

export default Project
