import styled, { keyframes } from "styled-components"

const multicolorHover = keyframes`
14.3% {
-webkit-text-fill-color: #ff0000;
   -webkit-text-stroke: 0.05vw  rgba(0, 0, 0, 0);
} 
     28.6% {
-webkit-text-fill-color: #f7ac1a;
   -webkit-text-stroke: 0.05vw  rgba(0, 0, 0, 0);
     } 
    42.9% {
-webkit-text-fill-color:  #ecef34;
   -webkit-text-stroke: 0.05vw  rgba(0, 0, 0, 0);
    }
    57.4% {
-webkit-text-fill-color:  #05ff00;
   -webkit-text-stroke: 0.05vw  rgba(0, 0, 0, 0);
    }  
    71.7% {
-webkit-text-fill-color:  #00f0ff;
   -webkit-text-stroke: 0.05vw  rgba(0, 0, 0, 0);
    }  
    86% {
-webkit-text-fill-color:  #2400ff;
   -webkit-text-stroke: 0.05vw  rgba(0, 0, 0, 0);
    }  
    100% {
-webkit-text-fill-color:  #fa00ff;
   -webkit-text-stroke: 0.05vw  rgba(0, 0, 0, 0);
    }  
`

const StyledProjects = styled.section`
  width: 80%;
  position: relative;
  & h1 {
    font-size: 4.5vw;
    line-height: 5vw;
    margin: 10rem 0;
    font-family: "Pano";
    -webkit-text-stroke: 0.05vw #000;
    -webkit-text-fill-color: rgba(255, 255, 255, 1);
    will-change: -webkit-text-fill-color, -webkit-text-stroke;
    text-shadow: 0.2rem 0.2rem #000000f5;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      animation: ${multicolorHover} 0.5s steps(7) infinite;
    }
  }
  & .project-image {
    display: none;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
    & h1 {
      font-size: 7vw;
      line-height: 9vw;
      margin: 5rem 0;
      -webkit-text-stroke: 0.2vw #000;
      text-shadow: 2px 2px #000000f5;
    }
  }

  /* & .rectangle-blend-mode {
    width: 400px;
    height: 150px;
    background: #07e7ff;
    mix-blend-mode: difference;
    z-index: 9999;
    position: absolute;
  } */
`

export default StyledProjects
