import React from "react"

function SvgComponent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 0H4a4 4 0 00-4 4v32a4 4 0 004 4h32a4 4 0 004-4V4a4 4 0 00-4-4zM2 4a2 2 0 012-2h32a2 2 0 012 2v32a2 2 0 01-2 2H4a2 2 0 01-2-2V4zm17.703 2.813a.812.812 0 111.625 0v3.25a.813.813 0 01-1.625 0v-3.25zM26 27v-5a6 6 0 00-12 0v5h5v-6.002a1 1 0 012 0V27h5zm2 0v-5a8 8 0 10-16 0v5h-1a1 1 0 00-1 1v4a1 1 0 001 1h18a1 1 0 001-1v-4a1 1 0 00-1-1h-1zm-16 2v2h16v-2H12zM30.551 9.15a.812.812 0 00-1.149 0l-2.298 2.299a.812.812 0 101.15 1.149L30.55 10.3a.812.812 0 000-1.15zm3.636 8.412a.812.812 0 110 1.625h-3.25a.812.812 0 110-1.625h3.25zM9.48 9.15a.812.812 0 000 1.15l2.298 2.297a.812.812 0 101.15-1.149L10.627 9.15a.812.812 0 00-1.149 0zM5.813 19.187a.813.813 0 010-1.625h3.25a.813.813 0 010 1.625h-3.25z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent
