import React, { useState, useEffect } from "react"
import gsap from "gsap"
import StyledLoaderContainer from "../styles/StyledLoaderContainer"
import StyledLoadingSquare from "../styles/StyledLoadingSquare"
import AnimatingLoaderTitle from "./AnimatingLoaderTitle"
import SquareSvg from "../images/svgs/SquareSvg"

const Loader = props => {
  const [names, setNames] = useState([])
  const [strokeDashoffset, setStrokeDashoffset] = useState(1250)
  const renderLoadingBackground = () => {
    const loadings = []
    for (let i = 0; i < 8; i++) {
      loadings.push(
        <div className="loader__spans--container" key={i}>
          <span className="loader__loading">loading</span>
          <span className="loader__loading">loading</span>
          <span className="loader__loading">loading</span>
          <span className="loader__loading">loading</span>
          <span className="loader__loading">loading</span>
        </div>
      )
    }
    setNames(loadings)
  }

  useEffect(() => {
    renderLoadingBackground()
    setTimeout(() => {
      setStrokeDashoffset(0)
    }, 100)
  }, [])

  return (
    <StyledLoaderContainer>
      <div className="loader__loadings--container">{names}</div>

      <StyledLoadingSquare>
        <SquareSvg
          className="loader__square"
          strokeDashoffset={strokeDashoffset}
        />
        <AnimatingLoaderTitle />
      </StyledLoadingSquare>
    </StyledLoaderContainer>
  )
}

export default Loader
