import React from "react"

function SvgComponent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 0H4a4 4 0 00-4 4v32a4 4 0 004 4h32a4 4 0 004-4V4a4 4 0 00-4-4zM2 4a2 2 0 012-2h32a2 2 0 012 2v32a2 2 0 01-2 2H4a2 2 0 01-2-2V4zm11.82 10.982c.4-.1.802-.202 1.19-.291a11.688 11.688 0 00-.01-.59C15 9.529 19.26 6 23.725 6c4.506 0 8.206 3.54 8.271 7.98 1.124.372 2.493 1 3.285 1.9a1 1 0 01-.043 1.368c-.36.36-1.036.895-1.85 1.317-.597.308-1.344.596-2.142.658-1.269 3.993-3.012 6.796-5.082 8.67A11.813 11.813 0 0123 29.96v4.04a1 1 0 01-2 0v-3.365c-.709.171-1.427.277-2.145.328a15.01 15.01 0 01-1.537.028v3.01a1 1 0 01-2 0v-3c0-.065.006-.128.018-.19-3.79-.551-7.354-2.205-9.774-3.386a1 1 0 01.318-1.892c2.137-.26 3.779-.94 5.04-1.857-1.956-1.19-3.033-3.182-3.57-5.04a12.634 12.634 0 01-.486-3.192c-.02-.924.075-1.791.295-2.376a1 1 0 011.7-.292c1.956 2.317 3.272 2.62 4.18 2.4l.782-.195zm3.756-.628c-.188-.005-.378 0-.569.012a13.308 13.308 0 00-.008-.284C17.012 10.818 20.166 8 23.726 8c3.487 0 6.272 2.754 6.272 6.1v.042a8.42 8.42 0 010 .323c-.002.223-.01.54-.034.908-.048.75-.156 1.665-.381 2.444-1.215 4.206-2.897 6.907-4.76 8.593-1.85 1.674-3.944 2.406-6.11 2.559-3.313.234-6.764-.885-9.633-2.118 1.597-.552 2.896-1.356 3.944-2.314 2.386.6 4.82.154 6.54-1.05.967-.678 1.73-1.615 2.087-2.76.36-1.155.282-2.442-.298-3.755-.39-.883-.916-1.552-1.6-1.995-.681-.443-1.433-.605-2.177-.623zM13.51 17.12c-1.534.372-3.067-.126-4.64-1.476.034.742.156 1.59.4 2.438.55 1.9 1.658 3.62 3.595 4.317 2.075.747 4.202.395 5.55-.55.668-.467 1.122-1.06 1.327-1.717.201-.647.187-1.435-.219-2.353-.269-.61-.57-.938-.858-1.125-.29-.188-.652-.29-1.137-.3-.497-.013-1.08.07-1.77.219-.453.097-.91.212-1.4.336-.271.068-.552.14-.848.21zm18.417-1.038a9.42 9.42 0 01-.109.988c.213-.073.431-.168.651-.281.152-.08.298-.163.434-.248a8.023 8.023 0 00-.976-.46z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent
