import React from "react"

function SvgComponent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0h32a4 4 0 014 4v32a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4zm0 2a2 2 0 00-2 2v32a2 2 0 002 2h32a2 2 0 002-2V4a2 2 0 00-2-2H4zm7.91 5.205a.813.813 0 011.57-.42l.841 3.138a.812.812 0 11-1.57.421l-.84-3.14zM6.821 10.25a.812.812 0 111.15-1.149l2.297 2.298a.813.813 0 01-1.149 1.15L6.821 10.25zM4 15.508c0 .449.364.812.813.812h3.25a.813.813 0 000-1.625h-3.25a.813.813 0 00-.813.813zM20 15c5.653 0 10.512 4.206 11.73 10H8.27C9.489 19.206 14.348 15 20 15zM10 27H7.053c-.572 0-1.031-.482-.963-1.05.83-6.959 6.223-12.415 12.91-12.913V12h-2.055a.833.833 0 01-.834-.833V11a4 4 0 018 0v.167c0 .46-.373.833-.833.833H21v1.037c6.687.498 12.08 5.954 12.91 12.913.068.569-.39 1.05-.963 1.05H30v1h1a3 3 0 110 6H9a3 3 0 110-6h1v-1zm2 0v1h16v-1H12zm8.111-18a2 2 0 011.733 1h-3.465a2 2 0 011.732-1zM10 30h21a1 1 0 110 2H9a1 1 0 110-2h1z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent
