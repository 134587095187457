import React from "react"

function SvgComponent(props) {
  return (
    <svg width={111} height={111} viewBox="0 0 111 111" {...props}>
      <defs>
        <radialGradient
          id="prefix__a"
          cx="50%"
          cy="50%"
          r="62.042%"
          fx="50%"
          fy="50%"
        >
          <stop offset="0%" stopColor="#4C4C4C" />
          <stop offset="100%" />
        </radialGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <circle cx={55.5} cy={55.5} r={55.5} fill="url(#prefix__a)" />
        <g fill="#3D3D3D" opacity={0.574} transform="translate(14 13)">
          <ellipse
            cx={16}
            cy={42.5}
            rx={1.5}
            ry={16}
            transform="matrix(0 -1 -1 0 58.5 58.5)"
          />
          <ellipse
            cx={68}
            cy={42.5}
            rx={1.5}
            ry={16}
            transform="rotate(90 68 42.5)"
          />
          <path d="M12.071 72.929c-.701-.702 3.847-6.387 10.16-12.699 6.311-6.312 11.996-10.86 12.698-10.159.701.702-3.847 6.387-10.16 12.699-6.311 6.312-11.996 10.86-12.698 10.159zm37-38c.702.701 6.387-3.847 12.699-10.16 6.312-6.311 10.86-11.996 10.159-12.698-.702-.701-6.387 3.847-12.699 10.16-6.312 6.311-10.86 11.996-10.159 12.698z" />
          <ellipse
            cx={42.5}
            cy={69}
            rx={1.5}
            ry={16}
            transform="matrix(-1 0 0 1 85 0)"
          />
          <ellipse cx={42.5} cy={16} rx={1.5} ry={16} />
          <path d="M71.929 72.929c-.702.701-6.387-3.847-12.699-10.16-6.312-6.311-10.86-11.996-10.159-12.698.702-.701 6.387 3.847 12.699 10.16 6.312 6.311 10.86 11.996 10.159 12.698zm-37-38c.701-.702-3.847-6.387-10.16-12.699-6.311-6.312-11.996-10.86-12.698-10.159-.701.702 3.847 6.387 10.16 12.699 6.311 6.312 11.996 10.86 12.698 10.159z" />
        </g>
        <circle cx={86} cy={38} r={8} fill="#FFF" />
      </g>
    </svg>
  )
}

export default SvgComponent
