import React, { useEffect, useState, useRef } from "react"
import StyledMyFun from "../styles/StyledMyFun"
import CircleGroup from "../images/svgs/CircleGroup"
import ZigZag from "../images/svgs/ZigZag"
import AlarmSvg from "../images/svgs/AlarmSvg"
import BellSvg from "../images/svgs/BellSvg"
import BirdSvg from "../images/svgs/BirdSvg"
import BombSvg from "../images/svgs/BombSvg"
import ClapSvg from "../images/svgs/ClapSvg"
import ClockSvg from "../images/svgs/ClockSvg"
import CowSvg from "../images/svgs/CowSvg"
import DogSvg from "../images/svgs/DogSvg"
import GunSvg from "../images/svgs/GunSvg"
import MusicalSvg from "../images/svgs/MusicalSvg"

import AlarmAudio from "../audio/alarm.wav"
import BirdAudio from "../audio/bird.mp3"
import BombAudio from "../audio/bomb.mp3"
import ClapAudio from "../audio/clap.mp3"
import CowAudio from "../audio/cow.mp3"
import DogAudio from "../audio/dog.mp3"
import BellAudio from "../audio/bell.mp3"
import LaserAudio from "../audio/laser.mp3"
import ClockAudio from "../audio/clock.wav"

const MyFun = ({ cms, toggleRoss }) => {
  const [selectedAudio, setSelectedAudio] = useState(null)
  const timer = useRef(null)
  const chooseAudio = async e => {
    const soundStr = e.currentTarget.dataset.sound
    let audio
    switch (soundStr) {
      case "bomb":
        setSelectedAudio(BombAudio)
        break
      case "bird":
        setSelectedAudio(BirdAudio)
        break
      case "alarm":
        setSelectedAudio(AlarmAudio)
        break
      case "cow":
        setSelectedAudio(CowAudio)
        break
      case "clock":
        setSelectedAudio(ClockAudio)
        break
      case "dog":
        setSelectedAudio(DogAudio)
        break
      case "laser":
        setSelectedAudio(LaserAudio)
        break
      case "clap":
        setSelectedAudio(ClapAudio)
        break
      case "bell":
        setSelectedAudio(BellAudio)
        break
      default:
        setSelectedAudio(DogAudio)
    }
    toggleRoss(true)
    // audio.play()
    toggleAnimate("add")
  }

  const toggleAnimate = action => {
    const willAnimate = [...document.querySelectorAll(".will-animate")]
    willAnimate.map(item => item.classList[action]("animate"))
  }

  const playAfterSelected = e => {
    chooseAudio(e).then(() => document.querySelector("#audio").play())
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      toggleRoss(false)
      toggleAnimate("remove")
      document.querySelector("#audio").pause()
    }, 4000)
  }

  useEffect(() => {
    const keys = [...document.querySelectorAll(".bottom .white")]
    keys.map(key => key.addEventListener("click", playAfterSelected, false))
  }, [])
  return (
    <StyledMyFun>
      <h2>{cms.myFun}</h2>
      <MusicalSvg className="music--svg" />
      <h1>
        In my spare time I make{" "}
        <a
          className="noise-link"
          href="https://open.spotify.com/artist/3QI9gBZJYxLUnrtRSsR6z0?si=PTYLZRMyTHeQQ70NP5uufg"
          target="_blank"
        >
          noise.
        </a>
      </h1>
      <audio id="audio" src={selectedAudio}></audio>
      <div className="noise-shapes">
        <div className="top-left">
          <ZigZag className="zig-zag will-animate" />
        </div>
        <div className="top-right">
          <CircleGroup className="circle-group will-animate" />
        </div>
        <div className="bottom">
          <li className="white b" data-sound="alarm">
            <AlarmSvg className="sound__icon" />
          </li>
          <li className="black as"></li>
          <li className="white a" data-sound="bell">
            <BellSvg className="sound__icon" />
          </li>
          <li className="black gs"></li>
          <li className="white g" data-sound="bird">
            <BirdSvg className="sound__icon" />
          </li>
          <li className="black fs"></li>
          <li className="white f" data-sound="bomb">
            <BombSvg className="sound__icon" />
          </li>
          <li className="white e" data-sound="clap">
            <ClapSvg className="sound__icon" />
          </li>
          <li className="black ds"></li>
          <li className="white d" data-sound="clock">
            <ClockSvg className="sound__icon" />
          </li>
          <li className="black cs"></li>
          <li className="white c" data-sound="cow">
            <CowSvg className="sound__icon" />
          </li>
          <li className="white b">
            <DogSvg className="sound__icon" data-sound="dog" />
          </li>
          <li className="black as"></li>
          <li className="white a" data-sound="laser">
            <GunSvg className="sound__icon" />
          </li>
        </div>
      </div>
    </StyledMyFun>
  )
}

export default MyFun
