import React from "react"

//  #ff0000 3.34%,
//     #f7ac1a 15.92%,
//     #ecef34 29.01%,
//     #05ff00 45.12%,
//     #00f0ff 55.69%,
//     #2400ff 72.81%,
//     #fa00ff 82.88%,
//     #ff0000 100%

const SquareSvg = props => (
  <svg width={312} height={312} viewBox="0 0 312 312" {...props}>
    <title>{"Group"}</title>
    <defs>
      <linearGradient id="bones-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="3.34%" style={{ stopColor: "#ff0000" }} />
        <stop offset="15.92%" style={{ stopColor: "#f7ac1a" }} />
        <stop offset="29.01%" style={{ stopColor: "#ecef34" }} />
        <stop offset="45.12%" style={{ stopColor: "#05ff00" }} />
        <stop offset="55.69%" style={{ stopColor: "#00f0ff" }} />
        <stop offset="72.81%" style={{ stopColor: "#2400ff" }} />
        <stop offset="82.88%" style={{ stopColor: "#fa00ff" }} />
        <stop offset="100%" style={{ stopColor: "#ff0000" }} />
      </linearGradient>
    </defs>
    <g strokeWidth={3} fill="none" fillRule="evenodd">
      <path
        stroke="url(#bones-gradient)"
        opacity="0.7"
        strokeOpacity={1}
        d="M2 2h308v308H2z"
      />
      <path
        stroke="#000"
        strokeDasharray={1250}
        strokeDashoffset={props.strokeDashoffset}
        d="M2 2h308v308H2z"
      />
    </g>
  </svg>
)

export default SquareSvg
