import React from "react"

function CircleGroup(props) {
  return (
    <svg width={1026} height={655} viewBox="0 0 1026 655" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(23)">
          <circle cx={136} cy={136} r={136} fill="#000" />
          <circle cx={136} cy={136} r={85} fill="#FFF" />
        </g>
        <g transform="translate(0 299)">
          <circle cx={178} cy={178} r={178} fill="#000" />
          <circle cx={178.5} cy={178.5} r={37.5} fill="#FFF" stroke="#979797" />
        </g>
        <g transform="translate(624 35)">
          <circle
            cx={152.5}
            cy={152.5}
            r={152.5}
            fill="#FFF"
            stroke="#000"
            strokeWidth={3}
          />
          <circle
            cx={153.5}
            cy={151.5}
            r={118.5}
            fill="#000"
            stroke="#979797"
          />
        </g>
        <g transform="translate(454 349)">
          <circle
            cx={102.5}
            cy={102.5}
            r={102.5}
            fill="#FFF"
            stroke="#000"
            strokeWidth={3}
          />
          <circle cx={102.5} cy={102.5} r={39.5} fill="#000" />
        </g>
        <circle
          cx={454}
          cy={213}
          r={114}
          fill="#FFF"
          stroke="#000"
          strokeWidth={3}
        />
        <circle
          cx={929}
          cy={477}
          r={95}
          fill="#FFF"
          stroke="#000"
          strokeWidth={3}
        />
      </g>
    </svg>
  )
}

export default CircleGroup
