import styled from "styled-components"

const StyledProgressBar = styled.div`
  height: 100%;
  position: fixed;
  width: 0.59vw;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 111;
  background: linear-gradient(
    to bottom,
    #ff4242 3.34%,
    #f9b93d 15.92%,
    #f3f656 29.01%,
    #55fe51 45.12%,
    #5bf5fe 55.69%,
    #745dff 72.81%,
    #fb4dff 82.88%,
    #ff4242 100%
  );
  transition: all 0.8s ease;
  @media only screen and (max-width: 640px) {
    width: 10px;
  }
`

export default StyledProgressBar
