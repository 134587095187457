import styled from "styled-components"

const StyledAbout = styled.section`
  width: 100%;
  padding-top: 8rem;
  margin: 0 auto;
  & .desktop-about {
    display: inline;
  }
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    padding-top: 8rem;
  }

  & h1 {
    width: 85%;
    position: relative;
    font-size: 5vw;
    line-height: 5.6vw;
    font-family: "Pano";
    font-weight: bold;
    will-change: transform;

    /* text-shadow: ${`${props => props.textShadow}px 4px 0px yellow`}; */
  }
  @media only screen and (max-width: 640px) {
    padding-top: 10rem;
    & h1 {
      width: 100%;
    font-size: 1.7rem;
    line-height: 2.7rem;
        text-shadow: 2px 2px #00ff9f6e;
    }
     & .desktop-about {
      display: none;
    }
  }
`

export default StyledAbout
