import React from "react"

function SvgComponent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 0H4a4 4 0 00-4 4v32a4 4 0 004 4h32a4 4 0 004-4V4a4 4 0 00-4-4zM2 4a2 2 0 012-2h32a2 2 0 012 2v32a2 2 0 01-2 2H4a2 2 0 01-2-2V4zm19 26.985a27.906 27.906 0 002.88-.227c4.04-.541 6.523-4.58 5.743-9.29a12.536 12.536 0 00-.39-1.704c-.522-1.654-1.764-4.402-2.975-6.915C25.121 10.487 22.69 9 20 9c-2.721 0-5.176 1.472-6.328 3.828-1.187 2.427-2.38 5.096-2.889 6.88a16.16 16.16 0 00-.392 1.812c-.763 4.692 1.679 8.686 5.678 9.231.869.118 1.843.202 2.931.234V26.85a3.107 3.107 0 01-.89-.444l-1.01-.728c-2.17-1.569-.914-4.676 1.891-4.676h2.018c2.805 0 4.062 3.107 1.89 4.676l-1.009.728a3.107 3.107 0 01-.89.444v4.135zM20 7c-2.47 0-4.808.963-6.488 2.652a2.5 2.5 0 00-.654-.584c-.745-.461-1.678-.497-2.392.011a16.666 16.666 0 00-3.508 3.394 19.566 19.566 0 00-1.852 2.904c-.708 1.368-.1 2.944 1.21 3.754.758.47 1.619.611 2.425.473a19.343 19.343 0 00-.324 1.595c-.883 5.432 1.929 10.79 7.382 11.534C17.02 32.899 18.415 33 20 33c1.561 0 2.937-.098 4.145-.26 5.49-.735 8.356-6.135 7.452-11.599a15.93 15.93 0 00-.326-1.532 3.474 3.474 0 002.394-.478c1.31-.81 1.918-2.386 1.21-3.754a19.57 19.57 0 00-1.852-2.904 16.666 16.666 0 00-3.508-3.394c-.714-.508-1.647-.472-2.393-.011a2.497 2.497 0 00-.675.613C24.785 7.98 22.463 7 20 7zM9.51 16.787l2.489-5.378a.504.504 0 00-.193-.64.35.35 0 00-.183-.059 14.668 14.668 0 00-3.078 2.98 17.558 17.558 0 00-1.663 2.607.63.63 0 00-.035.52c.069.202.236.437.52.613.763.471 1.766.17 2.142-.643zm20.962 0l-2.49-5.378a.504.504 0 01.193-.64.35.35 0 01.183-.059 14.67 14.67 0 013.078 2.98 17.556 17.556 0 011.663 2.607.63.63 0 01.035.52 1.21 1.21 0 01-.52.613 1.494 1.494 0 01-2.142-.643zm-11.102 8.26c.366.264.894.264 1.26 0l1.009-.73c.724-.522.305-1.558-.63-1.558H18.99c-.935 0-1.354 1.036-.63 1.559l1.009.728zM17 18a1 1 0 100-2 1 1 0 000 2zm6 0a1 1 0 100-2 1 1 0 000 2z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent
