import styled from "styled-components"

const StyledAwwwards = styled.div`
  width: 80%;
  height: 5.9vw;
  background: white;
  border: 0.05vw solid black;
  text-align: center;
  margin: 0 auto;
  position: relative;
  margin: 0 10% 5.9vw 0;
  cursor: pointer;
  @media only screen and (max-width: 640px) {
    width: 100%;
    height: 80px;
    border: 0.2vw solid black;
    margin: 0 10% 6rem 0;
    display: flex;
    align-items: center;
    background: #00ff9f;
  }
  &:hover {
    background: #00ff9f;
    & .awwwards--innerbox:nth-child(1) {
      background: #9579ff;
      transition-delay: 0.1s;
    }
  }
  & h2 {
    font-size: 1.14vw;
    line-height: 5.9vw;
    font-family: "Pano";
    font-weight: bold;
    margin: 0;
    z-index: 2;
    position: relative;
    @media only screen and (max-width: 640px) {
      font-size: 14px;
      line-height: 20px;
      width: 72%;
      margin: 0 auto;
    }
  }
  & .awwwards--innerbox {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    border: 0.05vw solid black;
    background: white;

    &:nth-child(1) {
      width: calc(100% - 1.48vw);
      height: calc(100% - 1.48vw);
    }
    &:nth-child(2) {
      width: calc(100% - 2.96vw);
      height: calc(100% - 2.96vw);
    }
    @media only screen and (max-width: 640px) {
      font-size: 12px;
      line-height: 80px;
      border: 0.2vw solid black;

      &:nth-child(1) {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        background: #9579ff;
      }
      &:nth-child(2) {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
      }
    }
  }
`

export default StyledAwwwards
