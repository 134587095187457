import React from "react"

function SvgComponent(props) {
  return (
    <svg width={40} height={40} viewBox="0 0 40 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 0H4a4 4 0 00-4 4v32a4 4 0 004 4h32a4 4 0 004-4V4a4 4 0 00-4-4zM2 4a2 2 0 012-2h32a2 2 0 012 2v32a2 2 0 01-2 2H4a2 2 0 01-2-2V4zm22 1a1 1 0 011 1v1.6a.997.997 0 01-.017.184c-.148.79-.49 1.4-1.073 1.786-.526.347-1.12.416-1.503.454l-.037.004c-.895.089-1.1.11-1.298.462-.073.13-.09.264-.054.486l.004.024H23a1 1 0 011 1v2.832c3.532 1.543 6 5.067 6 9.168 0 5.523-4.477 10-10 10s-10-4.477-10-10c0-4.1 2.468-7.625 6-9.168V12a1 1 0 011-1h2.009a2.585 2.585 0 01.319-1.49c.733-1.306 2.019-1.407 2.702-1.46.066-.006.126-.01.18-.016.384-.038.524-.084.596-.132l.002-.001c.019-.012.118-.076.192-.402V6a1 1 0 011-1zm-6 8h4v1h-4v-1zm2 3a8 8 0 100 16 8 8 0 000-16zm-2.142 2.486a1 1 0 01-.343 1.372c-.618.37-2.174 1.827-2.525 4.284a1 1 0 01-1.98-.283c.45-3.144 2.426-5.087 3.475-5.716a1 1 0 011.373.343z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent
